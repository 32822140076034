import App from './layouts/Index.vue'
import createRouter from "./router"
import { createHead } from "unhead"
import { createSSRApp } from 'vue'

import {messageInit} from "@/lang/message";
// @ts-ignore
import {errorHandler,sendLogToServer} from "@/ErrorProcess";
import ClientOnly from "@/components/common/clientOnly/ClientOnly.vue";

export default function() {
  // 加载翻译
  messageInit('en');

  const app = createSSRApp(App)

  if (import.meta.env.SSR) {
    // ... 仅在服务端执行的逻辑
  }

  if (typeof window !== 'undefined') {
      // 捕获未处理的Promise拒绝
      window.addEventListener('unhandledrejection', event => {
          // 将未处理的Promise拒绝信息发送到服务器
          sendLogToServer({
              message: event?.reason?.message,
              stack: event?.reason?.stack,
              url: window.location.href
          });
      });
      /**
       * 作用范围：window.onerror 是一个全局的错误处理函数，用于捕获浏览器环境中的所有未处理的 JavaScript 错误。它不仅捕获 Vue 组件中的错误，还包括其他非 Vue 代码（如原生 JavaScript 代码）中的错误。
       使用场景：当你希望捕获整个页面中的所有未处理的错误时，可以使用 window.onerror。这有助于你确保没有遗漏任何可能的错误，并且可以在全局范围内进行错误处理。
       */
      window.onerror = function (message, source, lineno, colno, error) {
          // 将错误信息发送到服务器
          sendLogToServer({
              message: message,
              source: source,
              lineno: lineno,
              colno: colno,
              stack: error ? error.stack : null,
              url: window.location.href
          });
      }
  }

  const router = createRouter()
  const head:any = createHead()
  app.use(head)
  app.use(router);
  app.component('client-only', ClientOnly);
  /**
   * errorHandler 是 Vue 实例的一个配置选项，用于捕获 Vue 组件渲染过程中发生的错误。它主要用于处理 Vue 组件内部的错误，包括模板渲染、事件处理、生命周期钩子等
   * 当你希望集中处理 Vue 组件中的错误时，可以使用 errorHandler。这有助于你在应用级别统一处理错误，而不需要在每个组件中都写错误处理逻辑。
   */
  app.config.errorHandler = errorHandler;
  return { app, router, head }
}