import {createApp, createVNode, nextTick, render} from "vue"
import AddToWishList from '@/components/common/addToWishList/AddToWishList.vue'

let currentWishListApp = null;
export function addToWishList(pids: number[], dom, excludeDirId: number = 0, store, actionType: string = 'default', successCallback: Function = null, cartId: number = null): Promise<any> {
    // 如果已有弹框，先关闭
    if (currentWishListApp) {
        currentWishListApp.unmount(); // 卸载当前弹框
        currentWishListApp = null; // 重置引用
    }

    const div = document.createElement('div');
    div.setAttribute('class', 'popWishList-container')
    // 挂载到body就可以在最顶层直接控制显示隐藏
    document.body.appendChild(div)

    return new Promise((resolve: any, reject)=>{
        // 封装组件属性方法
        const submitCallback = () => {
            //调用完毕后应该清空节点
            div.remove()
            currentWishListApp = null; // 重置引用
            resolve()
        }

        // 封装组件属性方法
        const cancelCallback = () => {
            try {
                div.remove();
                currentWishListApp = null;
                reject('Operation cancelled');
            } catch (error) {
                console.error('Error in cancelCallback:', error);
            }
        }

        // 在此处才创建节点并挂载属性
        currentWishListApp = createApp(AddToWishList, {
            submitCallback,
            cancelCallback,
            pids,
            excludeDirId,
            dom,
            div,
            actionType,
            successCallback,
            cartId
        });

        currentWishListApp.use(store).mount(div);

    });
}