import useUserStore from "@/store/user"
export function  errorHandler(err:any, vm, info) {
    // 获取用户状态管理实例
    const userStore = useUserStore();
    // 设置系统错误标志
    userStore.systemError = true;
    console.error("errorHandler")
    console.error(err.stack)
    // 检查是否在浏览器环境
    if (typeof window !== 'undefined') {
        // 构造日志对象
        const log = {
            stack: err.stack,
            info: info,
            url: window.location.href
        };
        // 发送日志到服务器
        sendLogToServer(log);
    }
}

export function sendLogToServer(log) {
        fetch('/client/logs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(log),
        }).catch(err => {
            console.error('Failed to send log to server:', err);
        });
}