<template>
  <div class="addList" @click.self="cancelCallback" :class="{ fade }">
    <em class="arrow"></em>
    <div class="addWist">
      <h2>Add to:</h2>
      <em class="wistClostIcon" @click.stop="cancelCallback" v-if="actionType =='default' || actionType =='hoverAdd'"></em>
      <div class="dropDownList" >
        <div class="dropDownListText" @click="isShowWishListDir = ! isShowWishListDir">
          {{defalutListObj.name}}
        </div>
        <ul class="dropDownListOption" v-if="isShowWishListDir">
          <li class="dirLi" v-for="(item, index) in wishList" :key="index" :title="item.name" @click="chooseDir(item)">{{item.name}}</li>
          <li class="newCouponWrap">
            <input type="text" v-model="createListDirName" class="newCouponinput" maxlength="50" >
            <input type="button" value="Add" class="newCouponbtn" @click="createListDir">
            <div style="clear: both"></div>
            <div class="errMsg" style="white-space: break-spaces;" v-if="createErrorMsg">{{createErrorMsg}}</div>
          </li>
        </ul>
      </div>
      <input type="button" class="addNowInput" value="Add Now" @click="addToWishList">
    </div>
  </div>

</template>

<script src="./AddToWishList.ts"></script>
<style src="./addToWishList.less" scoped lang="less"></style>